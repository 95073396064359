var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-page',{scopedSlots:_vm._u([{key:"resultlist",fn:function(){return [_c('k-crud-table',{attrs:{"headers":_vm.columns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.dataList.length,"item-key":"uuid"},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("数据列表")]),_c('v-spacer'),_c('k-primary-button',{attrs:{"icon":"mdi-plus","text":"新增权限"},on:{"click":function($event){_vm.crudActionMX().newItem(_vm.crud.default, _vm.onOpenEdit(_vm.crud.default))}}}),_c('k-crud-dialog',{attrs:{"title":_vm.crud.title},on:{"save":function () {
                  _vm.crudActionMX().saveItem();
                },"close":function () {
                  _vm.crudActionMX().closeEditingPage(_vm.crud);
                }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('div',{staticClass:"d-flex flex-column"},[_c('k-text-input',{staticClass:"my-2",attrs:{"label":"父权限ID","max-length":"45"},model:{value:(_vm.cruddataMX.data.parentId),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "parentId", $$v)},expression:"cruddataMX.data.parentId"}}),_c('k-text-input',{staticClass:"my-2",attrs:{"label":"权限伦理名","rules":[_vm.rules.required]},model:{value:(_vm.cruddataMX.data.menuName),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "menuName", $$v)},expression:"cruddataMX.data.menuName"}}),_c('k-text-input',{staticClass:"my-2",attrs:{"label":"权限物理名","rules":[_vm.rules.required]},model:{value:(_vm.cruddataMX.data.sign),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "sign", $$v)},expression:"cruddataMX.data.sign"}}),_c('k-text-input',{staticClass:"my-2",attrs:{"label":"权限KEY（开发用）"},model:{value:(_vm.cruddataMX.data.permission),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "permission", $$v)},expression:"cruddataMX.data.permission"}}),_c('k-text-input',{staticClass:"my-2",attrs:{"label":"显示顺序"},model:{value:(_vm.cruddataMX.data.sortOrder),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "sortOrder", $$v)},expression:"cruddataMX.data.sortOrder"}})],1)])]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)]},proxy:true},{key:"actions",fn:function(ref){
                var item = ref.item;
return [_c('k-table-warning-btn',{staticClass:"mr-2",attrs:{"text":"编辑"},on:{"click":function($event){_vm.crudActionMX().editItem(item, _vm.onOpenEdit(item))}}}),_c('k-table-error-btn',{attrs:{"text":"删除"},on:{"click":function($event){_vm.crudActionMX().deleteItem(item)}}})]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
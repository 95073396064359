<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <k-crud-page>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          :total="cruddataMX.dataList.length"
          item-key="uuid"
        >
          <template #icon="{ item }">
            <v-icon>{{ item.icon }}</v-icon>
          </template>
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>数据列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                icon="mdi-plus"
                text="新增权限"
                @click="
                  crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
                "
              />
              <k-crud-dialog
                v-model="crud.switch"
                :title="crud.title"
                @save="
                  () => {
                    crudActionMX().saveItem();
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
              >
                <template #dialogContent>
                  <v-form :ref="crud.form.ref">
                    <div class="d-flex flex-column">
                      <k-text-input
                        v-model="cruddataMX.data.parentId"
                        label="父权限ID"
                        class="my-2"
                        max-length="45"
                      />
                      <k-text-input
                        v-model="cruddataMX.data.menuName"
                        label="权限伦理名"
                        class="my-2"
                        :rules="[rules.required]"
                      />
                      <k-text-input
                        v-model="cruddataMX.data.sign"
                        label="权限物理名"
                        class="my-2"
                        :rules="[rules.required]"
                      />
                      <k-text-input
                        v-model="cruddataMX.data.permission"
                        label="权限KEY（开发用）"
                        class="my-2"
                      />
                      <k-text-input
                        v-model="cruddataMX.data.sortOrder"
                        label="显示顺序"
                        class="my-2"
                      />
                    </div>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <k-table-warning-btn
              class="mr-2"
              text="编辑"
              @click="crudActionMX().editItem(item, onOpenEdit(item))"
            >
            </k-table-warning-btn>
            <k-table-error-btn
              text="删除"
              @click="crudActionMX().deleteItem(item)"
            >
            </k-table-error-btn>
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import KTableWarningBtn from '@/components/koi/button/KTableWarningBtn';
import KTableErrorBtn from '@/components/koi/button/KTableErrorBtn';
export default {
  components: { KTableErrorBtn, KTableWarningBtn },
  data() {
    return {
      crud: {
        name: '菜单',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        select: {
          roles: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
            dataset: [],
          },
        },
        default: {
          id: '',
          parentId: '',
          sign: '',
          menuName: '',
          sortOrder: '',
          type: '1',
        },
      },
      columns: [
        { text: '权限ID', value: 'menuId' },
        { text: '父权限ID', value: 'parentId' },
        { text: '权限伦理名', value: 'menuName' },
        { text: '权限物理名', value: 'sign' },
        { text: '权限KEY（开发用）', value: 'permission' },
        { text: '显示顺序', value: 'sortOrder' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      rules: {
        required: (value) => !!value || '不能为空',
      },
      data: [],
    };
  },
  computed: {
    datetime() {
      return (timestamp) => this.$util.data.dataFormat(timestamp);
    },
    getname() {
      return (item) => item.map((p) => p.name).toString();
    },
  },
  created() {
    this.cruddataMX.data = Object.assign({}, this.crud.default);
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.dev.authority.getAuthority,
        add: this.$api.dev.authority.addAuthority,
        edit: this.$api.dev.authority.editAuthority,
        delete: this.$api.dev.authority.deleteAuthority,
      },
      {},
      {
        add: [
          'parentId',
          'sign',
          'permission',
          'menuName',
          'sortOrder',
          'type',
        ],
        edit: [
          'menuId',
          'parentId',
          'sign',
          'permission',
          'menuName',
          'sortOrder',
          'type',
        ],
      },
      false,
      () => {
        this.callbackAfterInit();
      }
    );
  },
  mounted() {},
  methods: {
    onOpenEdit() {
      this.crud.switch = true;
    },
    callbackAfterInit() {
      let newItems = [];
      for (var i = 0; i < this.cruddataMX.dataList.length; i++) {
        newItems.push(this.cruddataMX.dataList[i]);
        if (this.cruddataMX.dataList[i].children) {
          for (
            var j = 0;
            j < this.cruddataMX.dataList[i].children.length;
            j++
          ) {
            newItems.push(this.cruddataMX.dataList[i].children[j]);
          }
        }
      }
      this.cruddataMX.dataList = newItems;
    },
  },
};
</script>
